<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      'BackOffice': 'Escritório Virtual',
      'Dashboard': 'Painel',
      'Reports': 'Relatórios',
      'Ecommerce': 'Loja Virtual',
      'Orders': 'Pedidos',
      'Inventory': 'Estoque',
      'Products': 'Produtos',
      'Restock': 'Reposição',
      'Wallet': 'Carteira',
      'Extract': 'Extrato',
      'Balance': 'Saldo',
      'Voucher': 'Voucher',
      'Transfer': 'Transferir',
      'Withdrawal': 'Sacar',
      'Withdrawal History': 'Histórico de Saque',
    },
    es: {
      'BackOffice': 'Escritório Virtual',
      'Dashboard': 'Painel',
      'Reports': 'Relatórios',
      'Ecommerce': 'Loja Virtual',
      'Orders': 'Pedidos',
      'Inventory': 'Estoque',
      'Products': 'Produtos',
      'Restock': 'Reposição',
      'Wallet': 'Carteira',
      'Extract': 'Extrato',
      'Balance': 'Saldo',
      'Voucher': 'Voucher',
      'Transfer': 'Transferir',
      'Withdrawal': 'Sacar',
      'Withdrawal History': 'Histórico de Saque',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  mounted: function() {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <div id="sidebar-menu" class="pt-0">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="bg-soft-info">
        <router-link tag="a" to="/" class="side-nav-link-ref border-0 py-2 mt-1">
          <i class="bx bx-home-circle"></i>
          <span class="notranslate">{{ t('BackOffice') }}</span>
        </router-link>
      </li>
      <li class="bg-soft-warning">
        <router-link tag="a" to="/store/featured" class="side-nav-link-ref border-0 py-2 mt-1">
          <i class="bx bx-cart"></i>
          <span class="notranslate">{{ t('Loja Virtual') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Franquia') }}</li>
      <li>
        <router-link tag="a" to="/franchise" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span class="notranslate">{{ t('Dashboard') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/reports" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">{{ t('Reports') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/account" class="side-nav-link-ref">
          <i class="bx bx-building"></i>
          <span class="notranslate">{{ t('Minha Franquia') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Ecommerce') }}</li>
      <li>
        <router-link tag="a" to="/franchise/orders" class="side-nav-link-ref">
          <i class="bx bx-list-ol"></i>
          <span class="notranslate">{{ t('Orders') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/store/cart" class="side-nav-link-ref">
          <i class="bx bx-cart"></i>
          <span class="notranslate">{{ t('Lançar') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Pontuações') }}</li>
      <li>
        <router-link tag="a" to="/franchise/points/extract" class="side-nav-link-ref">
          <i class="bx bx-list-check"></i>
          <span class="notranslate">{{ t('Extrato') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/points/orders" class="side-nav-link-ref">
          <i class="bx bx-list-ol"></i>
          <span class="notranslate">{{ t('Orders') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/points/cart" class="side-nav-link-ref">
          <i class="bx bx-cart"></i>
          <span class="notranslate">{{ t('Lançar') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Inventory') }}</li>
      <li>
        <router-link tag="a" to="/franchise/inventory" class="side-nav-link-ref">
          <i class="bx bx-customize"></i>
          <span class="notranslate">{{ t('Products') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/inventory/orders" class="side-nav-link-ref">
          <i class="bx bx-list-ol"></i>
          <span class="notranslate">{{ t('Orders') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/inventory/restock" class="side-nav-link-ref">
          <i class="bx bxs-truck"></i>
          <span class="notranslate">{{ t('Restock') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Wallet') }}</li>
      <li>
        <router-link tag="a" to="/franchise/wallet/extract" class="side-nav-link-ref">
          <i class="bx bx-list-check"></i>
          <span class="notranslate">{{ t('Extract') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/wallet/transfer" class="side-nav-link-ref">
          <i class="bx bx-transfer-alt"></i>
          <span class="notranslate">{{ t('Transfer') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/wallet/withdrawal" class="side-nav-link-ref">
          <i class="bx bx-wallet-alt"></i>
          <span class="notranslate">{{ t('Withdrawal') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/franchise/wallet/withdrawal/history" class="side-nav-link-ref">
          <i class="bx bx-wallet"></i>
          <span class="notranslate">{{ t('Withdrawal History') }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: .9rem 1.5rem .7rem !important;
}
.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}
.py-2 i {
  margin-top: -4px;
}
</style>